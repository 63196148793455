* {
    margin: 0;
    padding: 0;
    font-family: "Consolas", monospace;
}

html, body {
    width: 100%;
    height: 100%;
}

body {
    background-color: #3C3C3C;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    color: white;
    font-size: 12px;
}

h1 {
    display: none;
}

a:link, a:visited {
    color: inherit;
    text-decoration: none;
}

a:hover, a:focus, a:active {
    text-decoration: none;
}

input[type="checkbox"],
input[type="radio"] {
    display: none;
}

/* Checkbox and label: */
input[type="checkbox"] + label,
input[type="radio"] + label {
    padding-left: 18px;
    position: relative;
}

/* Actual checkbox or radio button: */
input[type="checkbox"] + label::before,
input[type="radio"] + label::before {
    background-image: url("../resources/kurve-icons.png");
    background-repeat: no-repeat;
    box-sizing: border-box;
    content: "";
    display: inline-block;
    height: 12px;
    left: 0;
    position: absolute;
    top: 2px;
    width: 12px;
}

/* Actual checkbox: */
input[type="checkbox"] + label::before {
    border: 1px white solid;
    background-position: 0 -48px;
}

/* Actual checkbox on hover/focus: */
input[type="checkbox"] + label:hover::before, input[type="checkbox"] + label:focus::before {
    border: 1px white solid;
    background-position: -16px -48px;
}

/* Actual checkbox when checked: */
input[type="checkbox"]:checked + label::before {
    background-position: -32px -48px;
}

/* Actual checkbox when checked on hover/focus: */
input[type="checkbox"]:checked + label:hover::before, input[type="checkbox"]:checked + label:focus::before {
    background-position: -48px -48px;
}

/* Actual radio button: */
input[type="radio"] + label::before {
    background-position: 0 -64px;
}

/* Actual radio button on hover/focus: */
input[type="radio"] + label:hover::before, input[type="radio"] + label:focus::before {
    background-position: -16px -64px;
}

/* Actual radio button when checked: */
input[type="radio"]:checked + label::before {
    background-position: -32px -64px;
}

/* Actual radio button when checked on hover/focus: */
input[type="radio"]:checked + label:hover::before, input[type="radio"]:checked + label:focus::before {
    background-position: -48px -64px;
}

input[type="checkbox"] + label,
input[type="radio"] + label {
    padding-top: 1px;
    padding-bottom: 1px;
}

/* Checkbox and label on hover: */
input[type="checkbox"] + label,
input[type="radio"] + label,
select {
    cursor: pointer;
}

button {
    border: 1px solid rgba(255, 255, 255, 0.5);
    background-color: rgba(0, 0, 0, 0);
    color: white;
    height: 32px;
    cursor: pointer;
    width: calc(50% - 8px);
}

button:hover, button:focus, button:focus:active {
    outline: none;
}

button:focus {
    border-color: rgba(255, 255, 255, 1);
    background-color: rgba(255, 255, 255, 0.03);
}

button:hover {
    border-color: rgba(255, 255, 255, 1);
    background-color: rgba(255, 255, 255, 0.15);
}

.button {
    align-items: center;
    background-color: rgba(255, 255, 255, 0.16);
    border: 1px solid rgba(255, 255, 255, 0.64);
    box-sizing: border-box;
    color: rgba(255, 255, 255, 0.64);
    display: inline-flex;
    justify-content: center;
    padding: 4px;
}

.button:hover, .button:focus, .button:active {
    background-color: rgba(255, 255, 255, 0.24);
    border-color: rgba(255, 255, 255, 1.0);
    color: rgba(255, 255, 255, 1.0);
}

.fatalError {
    background-color: black;
    color: rgb(160, 144, 0);
    display: table; /* for vertical centering */
    font-size: 32px;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 10000;
}

.fatalError div {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
}

.fatalError .fatalErrorExtraInfo {
    color: rgb(144, 144, 144);
    font-size: 0.7em;
    margin-top: 0.5em;
}

.fatalError a:link, .fatalError a:visited {
    color: rgb(20, 50, 180);
}

.fatalError a:hover, .fatalError a:focus, .fatalError a:active {
    color: rgb(40, 70, 230);
}

.nocursor {
    cursor: none;
}

.nocursor.tempcursor {
    cursor: auto;
}

.icon-button {
    background-image: url("../resources/kurve-icons.png");
    cursor: pointer;
    height: 16px;
    opacity: 0.75;
    width: 16px;
}

.icon-button:hover, .icon-button:focus, .icon-button:active {
    opacity: 1;
}

.corner {
    position: absolute;
    right: 16px;
    top: 16px;
}

#wrapper {
    align-items: center;
    display: flex;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

.border {
    border-style: solid;
    border-width: 1px;
    display: flex; /* to prevent weird extra space at the bottom */
}

#border1 {
    border-color: #828282;
    position: relative; /* to allow absolute positioning of descendants*/
}

#border2 {
    border-color: #717171;
}

#border3 {
    border-color: #616161;
}

#border4 {
    border-color: #515151;
}

.flex {
    display: flex;
}

.overlay {
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    box-sizing: border-box;
}

.hidden {
    display: none !important;
}

#fatalError_UA {
    display: none;
}

#lobby, #KONEC_HRY, #canvas_main {
    background-color: black;
    overflow: hidden;
}

#lobby {
    padding-left: 81px;
    padding-top: 50px;
    padding-bottom: 130px; /* 130 = 480 - 7 * 50, where 50 is the total height of each player's controls and 7 is 6 players plus 1 for margin */
}

#lobby #controls {
    display: inline-block;
    list-style-type: none;
}

#lobby #controls li {
    height: 50px;
}

#lobby #controls li div {
    float: left;
    height: 100%;
}

#lobby #controls .controls {
    background-image: url("../resources/kurve-lobby-controls-ready.png");
    width: 160px;
}

#lobby #controls .ready {
    visibility: hidden;
    background-image: url("../resources/kurve-lobby-controls-ready.png");
    width: 80px;
}

#lobby #controls .ready.active {
    visibility: visible;
}

#lobby #controls .red.controls    { background-position:    0px    0px; }
#lobby #controls .red.ready       { background-position: -160px    0px; }
#lobby #controls .yellow.controls { background-position:    0px  -50px; }
#lobby #controls .yellow.ready    { background-position: -160px  -50px; }
#lobby #controls .orange.controls { background-position:    0px -100px; }
#lobby #controls .orange.ready    { background-position: -160px -100px; }
#lobby #controls .green.controls  { background-position:    0px -150px; }
#lobby #controls .green.ready     { background-position: -160px -150px; }
#lobby #controls .pink.controls   { background-position:    0px -200px; }
#lobby #controls .pink.ready      { background-position: -160px -200px; }
#lobby #controls .blue.controls   { background-position:    0px -250px; }
#lobby #controls .blue.ready      { background-position: -160px -250px; }

#button-show-settings {
    background-position: 0 -32px;
}

#button-hide-settings {
    background-position: 0 -16px;
    z-index: 10; /* so it stays in front of the right hand side :hover blocker */
}

#settings {
    background-color: black;
    overflow: hidden;
}

#settings {
    padding: 50px 80px 50px 80px;
}

#settings-form {
    height: 100%;
}

#settings-form > div {
    box-sizing: border-box;
    margin: 12px 0;
    transition-property: opacity;
    opacity: 0.6;
    vertical-align: top; /* so half-width divs are aligned properly */
}

#settings-form > div:hover {
    opacity: 1;
}

#settings-form > div,
#settings-form > div::before,
#settings-form > div .description {
    transition-duration: 200ms;
}

#settings-form > div.half-width {
    display: inline-block;
    width: calc(50% - 8px); /* 50% - half the margin-right */
    margin-top: 0; /* otherwise it will stack with the margin-bottom above */
    margin-right: 16px;
}

#settings-form > div.half-width.right-hand-side {
    /* A half-width div on the right hand side must have no margin-right, so it fits in the available horizontal space. */
    margin-right: 0;
}

#settings-form > div.half-width + div {
    /* A full-width div right after a half-width one must have no margin-top; otherwise their margins will stack. */
    margin-top: 0;
}

#settings-form fieldset {
    border: 1px solid white;
    padding: 2px 8px 6px 8px;
}

#settings-form fieldset legend {
    padding: 0 4px;
}

#settings-form fieldset input[type="radio"] + label {
    margin-left: 4px; /* to align with legend */
}

#settings-form label {
    display: inline-block;
}

#settings-form .description {
    box-sizing: border-box;
    font-size: 0.75em;
    left: 0;
    bottom: 30px; /* Match with #settings::after height! */
    margin-left: calc(80px - 16px + 1px);
    padding-bottom: 4px;
    padding-left: 16px;
    padding-right: 4px;
    position: absolute;
    max-width: calc(100% - 160px + 16px - 1px);
    border-bottom-style: solid;
    pointer-events: none;
}

#settings-form > div.half-width.right-hand-side .description {
    left: unset;
    right: 0;
    padding-left: 4px;
    padding-right: 16px;
    margin-left: unset;
    margin-right: calc(80px - 16px + 1px);
}

/* To clip the connection to the description: */
#settings::after {
    background-color: black;
    content: "";
    display: block;
    height: 30px; /* Match with .description bottom! */
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
}

#settings-form > div::before,
#settings-form > div .description {
    opacity: 0;
    border-width: 1px; /* apparently necessary, otherwise it is always the same (wrong) thickness regardless of scale */
    transition-property: opacity;
    border-color: rgba(255, 255, 255, 0.6);
}

#settings-form > div:hover::before,
#settings-form > div:hover .description {
    opacity: 0.9;
}

#settings-form > div:hover::before,
#settings-form > div:hover .description {
    transition-property: opacity;
    transition-delay: 500ms;
    transition-duration: 1s;
}

#settings-form > div::before {
    border-left-style: solid;
    border-top-style: solid;
    content: "";
    height: 1000%;
    left: 64px;
    margin-top: 7px;
    position: absolute;
    width: 7px;
    pointer-events: none;
}

#settings-form > div.half-width.right-hand-side::before {
    border-left-style: none;
    border-right-style: solid;
    content: "";
    left: unset;
    right: 64px;
}


#settings-form select {
    background: black;
    border: 1px solid white;
    color: white;
    margin-top: 2px;
    width: 219px;
}

#settings-form input[type="radio"] + label {
    display: block;
}

#messages {
    top: 350px; /* 350 = 7 * 50, where 50 is the total height of each player's controls and 7 is 6 players plus 1 for margin */
    height: unset; // otherwise 100% from .overlay
}

.message {
    font-size: 8px;
    padding: 2px 80px;
    text-align: center;
    text-shadow: 0 0 2px black, 0 0 2px black, 0 0 4px black, 0 0 4px black, 0 0 4px black, 0 0 4px black, 0 0 8px black, 0 0 8px black, 0 0 8px black, 0 0 8px black;
}

.info.message {
    color: white;
}

.warning.message {
    color: #C3C300;
}

#messages.hints-none .message,
#messages.hints-warnings-only .message {
    display: none;
}

#messages.hints-warnings-only .message.warning {
    display: block;
}

#dialogs {
    align-items: center;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
}

.dialog {
    background-color: rgba(0, 0, 0, 0.9);
    border: 1px solid white;
    cursor: auto !important;
    padding: 16px;
    width: 240px;
}

.dialog p {
    min-height: 64px;
}

.alert.dialog button {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.confirmation.dialog button {
    margin-right: 16px;
}

.confirmation.dialog button:last-of-type {
    margin-right: 0;
}



.canvasHeight {
    height: 480px;
}

#left, #scoreboard {
    box-sizing: border-box;
}

#scoreboard {
    width: 77px;
    padding: 20px 12px 0 9px;
}

#left {
	width: 76px; /* 1 less than #scoreboard so width of #wrapper is an even number */
}

#KONEC_HRY {
    background-image: url("../resources/kurve-konec-hry.png");
}

#KONEC_HRY #results {
    list-style-type: none;
    margin-top: 80px;
    margin-left: 250px;
}

/* General rules for digits: */
.digits {
    visibility: hidden;
}

.active.digits {
    visibility: visible;
}

.digits > div {
    display: inline-block;
    float: left;
}

.red.digits    div { background-image: url("../resources/kurve-digits-red.png");    }
.yellow.digits div { background-image: url("../resources/kurve-digits-yellow.png"); }
.orange.digits div { background-image: url("../resources/kurve-digits-orange.png"); }
.green.digits  div { background-image: url("../resources/kurve-digits-green.png");  }
.pink.digits   div { background-image: url("../resources/kurve-digits-pink.png");   }
.blue.digits   div { background-image: url("../resources/kurve-digits-blue.png");   }

/* Scoreboard digits: */
#scoreboard .digits {
    height: 43px;
    margin-bottom: 37px;
}

#scoreboard .digits > div {
    height: 43px;
    width: 28px;
}

#scoreboard .digits .d0 { background-position:    0px 0; }
#scoreboard .digits .d1 { background-position:  -28px 0; }
#scoreboard .digits .d2 { background-position:  -56px 0; }
#scoreboard .digits .d3 { background-position:  -84px 0; }
#scoreboard .digits .d4 { background-position: -112px 0; }
#scoreboard .digits .d5 { background-position: -140px 0; }
#scoreboard .digits .d6 { background-position: -168px 0; }
#scoreboard .digits .d7 { background-position: -196px 0; }
#scoreboard .digits .d8 { background-position: -224px 0; }
#scoreboard .digits .d9 { background-position: -252px 0; }

/* KONEC HRY digits: */
#KONEC_HRY .digits {
    height: 14px;
    margin-bottom: 26px;
}

#KONEC_HRY .digits > div {
    height: 14px;
    width: 16px;
}

#KONEC_HRY .digits .d0 { background-position:    0px -43px; }
#KONEC_HRY .digits .d1 { background-position:  -28px -43px; }
#KONEC_HRY .digits .d2 { background-position:  -56px -43px; }
#KONEC_HRY .digits .d3 { background-position:  -84px -43px; }
#KONEC_HRY .digits .d4 { background-position: -112px -43px; }
#KONEC_HRY .digits .d5 { background-position: -140px -43px; }
#KONEC_HRY .digits .d6 { background-position: -168px -43px; }
#KONEC_HRY .digits .d7 { background-position: -196px -43px; }
#KONEC_HRY .digits .d8 { background-position: -224px -43px; }
#KONEC_HRY .digits .d9 { background-position: -252px -43px; }




/*************************/
/******** SCALING ********/
/*************************/

/*
640 = Kurve total width
480 = Kurve total height
559 = Kurve canvas width (excluding border)
77  = #scoreboard width (excluding border)
76  = #left width (excluding border)
720 = 76 + 4 + 559 + 4 + 77 = total width including borders, #left, and #scoreboard
*/

@media screen and (max-width: 639px) {
    #left { display: none; }
    body#ZATACKA { justify-content: flex-start; }
    #wrapper { transform-origin: center; }
    .border { border-left-width: 0; }
}


/* ======== 1x ======== */
@media (min-width: 640px) and (min-height: 480px) {
    body#ZATACKA.body { justify-content: flex-end; }
    body#ZATACKA.body { transform-origin: right; }
    body.body #wrapper {
        zoom: 1;
        -moz-transform: scale(1);
    }
    body.body * {
        image-rendering: -moz-crisp-edges;
        image-rendering: pixelated;
    }
}

@media (min-width: 720px) and (min-height: 480px) {
    body#ZATACKA.body { justify-content: center; }
    body#ZATACKA.body #wrapper { transform-origin: center; }
}


/* ======== 1x (2dppx) ======== */
@media
    (min-width: 360px) and (min-height: 240px) and (-webkit-device-pixel-ratio: 2),
    (min-width: 360px) and (min-height: 240px) and (-webkit-resolution: 2),
    (min-width: 360px) and (min-height: 240px) and (resolution: 2)
{
    body#ZATACKA.body { justify-content: flex-end; }
    body#ZATACKA.body { transform-origin: right; }
    body.body #wrapper {
        zoom: 0.5;
        -moz-transform: scale(0.5);
    }
    body.body * {
        image-rendering: -moz-crisp-edges;
        image-rendering: pixelated;
    }
}

@media
    (min-width: 360px) and (min-height: 240px) and (-webkit-device-pixel-ratio: 2),
    (min-width: 360px) and (min-height: 240px) and (-webkit-resolution: 2),
    (min-width: 360px) and (min-height: 240px) and (resolution: 2)
{
    body#ZATACKA.body { justify-content: center; }
    body#ZATACKA.body #wrapper { transform-origin: center; }
}


/* ======== 1.25x ======== (only used if pixelmapping is not enforced) */
@media (min-width: 800px) and (min-height: 600px) {
    body#ZATACKA.blurry { justify-content: flex-end; }
    body#ZATACKA.blurry { transform-origin: right; }
    body.blurry #wrapper {
        zoom: 1.25;
        -moz-transform: scale(1.25);
    }
    body.blurry * {
        image-rendering: auto;
    }
}

@media (min-width: 900px) and (min-height: 600px) {
    body#ZATACKA.blurry { justify-content: center; }
    body#ZATACKA.blurry #wrapper { transform-origin: center; }
}


/* ======== 1.25x (2dppx) ======== (only used if pixelmapping is not enforced) */
@media
    (min-width: 450px) and (min-height: 300px) and (-webkit-device-pixel-ratio: 2),
    (min-width: 450px) and (min-height: 300px) and (-webkit-resolution: 2),
    (min-width: 450px) and (min-height: 300px) and (resolution: 2)
{
    body#ZATACKA.blurry { justify-content: flex-end; }
    body#ZATACKA.blurry { transform-origin: right; }
    body.blurry #wrapper {
        zoom: 0.625;
        -moz-transform: scale(0.625);
    }
    body.blurry * {
        image-rendering: auto;
    }
}

@media
    (min-width: 450px) and (min-height: 300px) and (-webkit-device-pixel-ratio: 2),
    (min-width: 450px) and (min-height: 300px) and (-webkit-resolution: 2),
    (min-width: 450px) and (min-height: 300px) and (resolution: 2)
{
    body#ZATACKA.blurry { justify-content: center; }
    body#ZATACKA.blurry #wrapper { transform-origin: center; }
}


/* ======== 1.5x ======== (only used if pixelmapping is not enforced) */
@media (min-width: 960px) and (min-height: 720px) {
    body#ZATACKA.blurry { justify-content: flex-end; }
    body#ZATACKA.blurry { transform-origin: right; }
    body.blurry #wrapper {
        zoom: 1.5;
        -moz-transform: scale(1.5);
    }
    body.blurry * {
        image-rendering: auto;
    }
}

@media (min-width: 1080px) and (min-height: 720px) {
    body#ZATACKA.blurry { justify-content: center; }
    body#ZATACKA.blurry #wrapper { transform-origin: center; }
}


/* ======== 1.5x (2dppx) ======== (only used if pixelmapping is not enforced) */
@media
    (min-width: 540px) and (min-height: 360px) and (-webkit-device-pixel-ratio: 2),
    (min-width: 540px) and (min-height: 360px) and (-webkit-resolution: 2),
    (min-width: 540px) and (min-height: 360px) and (resolution: 2)
{
    body#ZATACKA.blurry { justify-content: flex-end; }
    body#ZATACKA.blurry { transform-origin: right; }
    body.blurry #wrapper {
        zoom: 0.75;
        -moz-transform: scale(0.75);
    }
    body.blurry * {
        image-rendering: auto;
    }
}

@media
    (min-width: 540px) and (min-height: 360px) and (-webkit-device-pixel-ratio: 2),
    (min-width: 540px) and (min-height: 360px) and (-webkit-resolution: 2),
    (min-width: 540px) and (min-height: 360px) and (resolution: 2)
{
    body#ZATACKA.blurry { justify-content: center; }
    body#ZATACKA.blurry #wrapper { transform-origin: center; }
}


/* ======== 1.75x ======== (only used if pixelmapping is not enforced) */
@media (min-width: 1120px) and (min-height: 840px) {
    body#ZATACKA.blurry { justify-content: flex-end; }
    body#ZATACKA.blurry { transform-origin: right; }
    body.blurry #wrapper {
        zoom: 1.75;
        -moz-transform: scale(1.75);
    }
    body.blurry * {
        image-rendering: auto;
    }
}

@media (min-width: 1260px) and (min-height: 840px) {
    body#ZATACKA.blurry { justify-content: center; }
    body#ZATACKA.blurry #wrapper { transform-origin: center; }
}


/* ======== 1.75x (2dppx) ======== (only used if pixelmapping is not enforced) */
@media
    (min-width: 630px) and (min-height: 420px) and (-webkit-device-pixel-ratio: 2),
    (min-width: 630px) and (min-height: 420px) and (-webkit-resolution: 2),
    (min-width: 630px) and (min-height: 420px) and (resolution: 2)
{
    body#ZATACKA.blurry { justify-content: flex-end; }
    body#ZATACKA.blurry { transform-origin: right; }
    body.blurry #wrapper {
        zoom: 0.875;
        -moz-transform: scale(0.875);
    }
    body.blurry * {
        image-rendering: auto;
    }
}

@media
    (min-width: 630px) and (min-height: 420px) and (-webkit-device-pixel-ratio: 2),
    (min-width: 630px) and (min-height: 420px) and (-webkit-resolution: 2),
    (min-width: 630px) and (min-height: 420px) and (resolution: 2)
{
    body#ZATACKA.blurry { justify-content: center; }
    body#ZATACKA.blurry #wrapper { transform-origin: center; }
}


/* ======== 2x ======== */
@media (min-width: 1280px) and (min-height: 960px) {
    body#ZATACKA.body { justify-content: flex-end; }
    body#ZATACKA.body { transform-origin: right; }
    body.body #wrapper {
        zoom: 2;
        -moz-transform: scale(2);
    }
    body.body * {
        image-rendering: -moz-crisp-edges;
        image-rendering: pixelated;
    }
}

@media (min-width: 1440px) and (min-height: 960px) {
    body#ZATACKA.body { justify-content: center; }
    body#ZATACKA.body #wrapper { transform-origin: center; }
}


/* ======== 2x (2dppx) ======== */
@media
    (min-width: 720px) and (min-height: 480px) and (-webkit-device-pixel-ratio: 2),
    (min-width: 720px) and (min-height: 480px) and (-webkit-resolution: 2),
    (min-width: 720px) and (min-height: 480px) and (resolution: 2)
{
    body#ZATACKA.body { justify-content: flex-end; }
    body#ZATACKA.body { transform-origin: right; }
    body.body #wrapper {
        zoom: 1;
        -moz-transform: scale(1);
    }
    body.body * {
        image-rendering: -moz-crisp-edges;
        image-rendering: pixelated;
    }
}

@media
    (min-width: 720px) and (min-height: 480px) and (-webkit-device-pixel-ratio: 2),
    (min-width: 720px) and (min-height: 480px) and (-webkit-resolution: 2),
    (min-width: 720px) and (min-height: 480px) and (resolution: 2)
{
    body#ZATACKA.body { justify-content: center; }
    body#ZATACKA.body #wrapper { transform-origin: center; }
}


/* ======== 2.5x ======== (only used if pixelmapping is not enforced) */
@media (min-width: 1600px) and (min-height: 1200px) {
    body#ZATACKA.blurry { justify-content: flex-end; }
    body#ZATACKA.blurry { transform-origin: right; }
    body.blurry #wrapper {
        zoom: 2.5;
        -moz-transform: scale(2.5);
    }
    body.blurry * {
        image-rendering: auto;
    }
}

@media (min-width: 1800px) and (min-height: 1200px) {
    body#ZATACKA.blurry { justify-content: center; }
    body#ZATACKA.blurry #wrapper { transform-origin: center; }
}


/* ======== 2.5x (2dppx) ======== (only used if pixelmapping is not enforced) */
@media
    (min-width: 900px) and (min-height: 600px) and (-webkit-device-pixel-ratio: 2),
    (min-width: 900px) and (min-height: 600px) and (-webkit-resolution: 2),
    (min-width: 900px) and (min-height: 600px) and (resolution: 2)
{
    body#ZATACKA.blurry { justify-content: flex-end; }
    body#ZATACKA.blurry { transform-origin: right; }
    body.blurry #wrapper {
        zoom: 1.25;
        -moz-transform: scale(1.25);
    }
    body.blurry * {
        image-rendering: auto;
    }
}

@media
    (min-width: 900px) and (min-height: 600px) and (-webkit-device-pixel-ratio: 2),
    (min-width: 900px) and (min-height: 600px) and (-webkit-resolution: 2),
    (min-width: 900px) and (min-height: 600px) and (resolution: 2)
{
    body#ZATACKA.blurry { justify-content: center; }
    body#ZATACKA.blurry #wrapper { transform-origin: center; }
}


/* ======== 3x ======== */
@media (min-width: 1920px) and (min-height: 1440px) {
    body#ZATACKA.body { justify-content: flex-end; }
    body#ZATACKA.body { transform-origin: right; }
    body.body #wrapper {
        zoom: 3;
        -moz-transform: scale(3);
    }
    body.body * {
        image-rendering: -moz-crisp-edges;
        image-rendering: pixelated;
    }
}

@media (min-width: 2160px) and (min-height: 1440px) {
    body#ZATACKA.body { justify-content: center; }
    body#ZATACKA.body #wrapper { transform-origin: center; }
}


/* ======== 3x (2dppx) ======== */
@media
    (min-width: 1080px) and (min-height: 720px) and (-webkit-device-pixel-ratio: 2),
    (min-width: 1080px) and (min-height: 720px) and (-webkit-resolution: 2),
    (min-width: 1080px) and (min-height: 720px) and (resolution: 2)
{
    body#ZATACKA.body { justify-content: flex-end; }
    body#ZATACKA.body { transform-origin: right; }
    body.body #wrapper {
        zoom: 1.5;
        -moz-transform: scale(1.5);
    }
    body.body * {
        image-rendering: -moz-crisp-edges;
        image-rendering: pixelated;
    }
}

@media
    (min-width: 1080px) and (min-height: 720px) and (-webkit-device-pixel-ratio: 2),
    (min-width: 1080px) and (min-height: 720px) and (-webkit-resolution: 2),
    (min-width: 1080px) and (min-height: 720px) and (resolution: 2)
{
    body#ZATACKA.body { justify-content: center; }
    body#ZATACKA.body #wrapper { transform-origin: center; }
}


/* ======== 4x ======== */
@media (min-width: 2560px) and (min-height: 1920px) {
    body#ZATACKA.body { justify-content: flex-end; }
    body#ZATACKA.body { transform-origin: right; }
    body.body #wrapper {
        zoom: 4;
        -moz-transform: scale(4);
    }
    body.body * {
        image-rendering: -moz-crisp-edges;
        image-rendering: pixelated;
    }
}

@media (min-width: 2880px) and (min-height: 1920px) {
    body#ZATACKA.body { justify-content: center; }
    body#ZATACKA.body #wrapper { transform-origin: center; }
}


/* ======== 4x (2dppx) ======== */
@media
    (min-width: 1440px) and (min-height: 960px) and (-webkit-device-pixel-ratio: 2),
    (min-width: 1440px) and (min-height: 960px) and (-webkit-resolution: 2),
    (min-width: 1440px) and (min-height: 960px) and (resolution: 2)
{
    body#ZATACKA.body { justify-content: flex-end; }
    body#ZATACKA.body { transform-origin: right; }
    body.body #wrapper {
        zoom: 2;
        -moz-transform: scale(2);
    }
    body.body * {
        image-rendering: -moz-crisp-edges;
        image-rendering: pixelated;
    }
}

@media
    (min-width: 1440px) and (min-height: 960px) and (-webkit-device-pixel-ratio: 2),
    (min-width: 1440px) and (min-height: 960px) and (-webkit-resolution: 2),
    (min-width: 1440px) and (min-height: 960px) and (resolution: 2)
{
    body#ZATACKA.body { justify-content: center; }
    body#ZATACKA.body #wrapper { transform-origin: center; }
}


/* ======== 5x ======== */
@media (min-width: 3200px) and (min-height: 2400px) {
    body#ZATACKA.body { justify-content: flex-end; }
    body#ZATACKA.body { transform-origin: right; }
    body.body #wrapper {
        zoom: 5;
        -moz-transform: scale(5);
    }
    body.body * {
        image-rendering: -moz-crisp-edges;
        image-rendering: pixelated;
    }
}

@media (min-width: 3600px) and (min-height: 2400px) {
    body#ZATACKA.body { justify-content: center; }
    body#ZATACKA.body #wrapper { transform-origin: center; }
}


/* ======== 5x (2dppx) ======== */
@media
    (min-width: 1800px) and (min-height: 1200px) and (-webkit-device-pixel-ratio: 2),
    (min-width: 1800px) and (min-height: 1200px) and (-webkit-resolution: 2),
    (min-width: 1800px) and (min-height: 1200px) and (resolution: 2)
{
    body#ZATACKA.body { justify-content: flex-end; }
    body#ZATACKA.body { transform-origin: right; }
    body.body #wrapper {
        zoom: 2.5;
        -moz-transform: scale(2.5);
    }
    body.body * {
        image-rendering: -moz-crisp-edges;
        image-rendering: pixelated;
    }
}

@media
    (min-width: 1800px) and (min-height: 1200px) and (-webkit-device-pixel-ratio: 2),
    (min-width: 1800px) and (min-height: 1200px) and (-webkit-resolution: 2),
    (min-width: 1800px) and (min-height: 1200px) and (resolution: 2)
{
    body#ZATACKA.body { justify-content: center; }
    body#ZATACKA.body #wrapper { transform-origin: center; }
}


/* ======== 6x ======== */
@media (min-width: 3840px) and (min-height: 2880px) {
    body#ZATACKA.body { justify-content: flex-end; }
    body#ZATACKA.body { transform-origin: right; }
    body.body #wrapper {
        zoom: 6;
        -moz-transform: scale(6);
    }
    body.body * {
        image-rendering: -moz-crisp-edges;
        image-rendering: pixelated;
    }
}

@media (min-width: 4320px) and (min-height: 2880px) {
    body#ZATACKA.body { justify-content: center; }
    body#ZATACKA.body #wrapper { transform-origin: center; }
}


/* ======== 6x (2dppx) ======== */
@media
    (min-width: 2160px) and (min-height: 1440px) and (-webkit-device-pixel-ratio: 2),
    (min-width: 2160px) and (min-height: 1440px) and (-webkit-resolution: 2),
    (min-width: 2160px) and (min-height: 1440px) and (resolution: 2)
{
    body#ZATACKA.body { justify-content: flex-end; }
    body#ZATACKA.body { transform-origin: right; }
    body.body #wrapper {
        zoom: 3;
        -moz-transform: scale(3);
    }
    body.body * {
        image-rendering: -moz-crisp-edges;
        image-rendering: pixelated;
    }
}

@media
    (min-width: 2160px) and (min-height: 1440px) and (-webkit-device-pixel-ratio: 2),
    (min-width: 2160px) and (min-height: 1440px) and (-webkit-resolution: 2),
    (min-width: 2160px) and (min-height: 1440px) and (resolution: 2)
{
    body#ZATACKA.body { justify-content: center; }
    body#ZATACKA.body #wrapper { transform-origin: center; }
}


/* ======== 7x ======== */
@media (min-width: 4480px) and (min-height: 3360px) {
    body#ZATACKA.body { justify-content: flex-end; }
    body#ZATACKA.body { transform-origin: right; }
    body.body #wrapper {
        zoom: 7;
        -moz-transform: scale(7);
    }
    body.body * {
        image-rendering: -moz-crisp-edges;
        image-rendering: pixelated;
    }
}

@media (min-width: 5040px) and (min-height: 3360px) {
    body#ZATACKA.body { justify-content: center; }
    body#ZATACKA.body #wrapper { transform-origin: center; }
}


/* ======== 7x (2dppx) ======== */
@media
    (min-width: 2520px) and (min-height: 1680px) and (-webkit-device-pixel-ratio: 2),
    (min-width: 2520px) and (min-height: 1680px) and (-webkit-resolution: 2),
    (min-width: 2520px) and (min-height: 1680px) and (resolution: 2)
{
    body#ZATACKA.body { justify-content: flex-end; }
    body#ZATACKA.body { transform-origin: right; }
    body.body #wrapper {
        zoom: 3.5;
        -moz-transform: scale(3.5);
    }
    body.body * {
        image-rendering: -moz-crisp-edges;
        image-rendering: pixelated;
    }
}

@media
    (min-width: 2520px) and (min-height: 1680px) and (-webkit-device-pixel-ratio: 2),
    (min-width: 2520px) and (min-height: 1680px) and (-webkit-resolution: 2),
    (min-width: 2520px) and (min-height: 1680px) and (resolution: 2)
{
    body#ZATACKA.body { justify-content: center; }
    body#ZATACKA.body #wrapper { transform-origin: center; }
}


/* ======== 8x ======== */
@media (min-width: 5120px) and (min-height: 3840px) {
    body#ZATACKA.body { justify-content: flex-end; }
    body#ZATACKA.body { transform-origin: right; }
    body.body #wrapper {
        zoom: 8;
        -moz-transform: scale(8);
    }
    body.body * {
        image-rendering: -moz-crisp-edges;
        image-rendering: pixelated;
    }
}

@media (min-width: 5760px) and (min-height: 3840px) {
    body#ZATACKA.body { justify-content: center; }
    body#ZATACKA.body #wrapper { transform-origin: center; }
}


/* ======== 8x (2dppx) ======== */
@media
    (min-width: 2880px) and (min-height: 1920px) and (-webkit-device-pixel-ratio: 2),
    (min-width: 2880px) and (min-height: 1920px) and (-webkit-resolution: 2),
    (min-width: 2880px) and (min-height: 1920px) and (resolution: 2)
{
    body#ZATACKA.body { justify-content: flex-end; }
    body#ZATACKA.body { transform-origin: right; }
    body.body #wrapper {
        zoom: 4;
        -moz-transform: scale(4);
    }
    body.body * {
        image-rendering: -moz-crisp-edges;
        image-rendering: pixelated;
    }
}

@media
    (min-width: 2880px) and (min-height: 1920px) and (-webkit-device-pixel-ratio: 2),
    (min-width: 2880px) and (min-height: 1920px) and (-webkit-resolution: 2),
    (min-width: 2880px) and (min-height: 1920px) and (resolution: 2)
{
    body#ZATACKA.body { justify-content: center; }
    body#ZATACKA.body #wrapper { transform-origin: center; }
}
